import {$jQuery} from 'jquery';
/*IMPORT SCSS*/
import '../styles/index.scss';
import { totalmem } from 'os';


$( document ).ready(function() {

  /*Åpne meny
  $('#burger').click(function() {
  $( '.hamburger' ).toggleClass('is-active');
  $( '.header .meny' ).toggleClass('in');
  $( '.header .logo' ).toggleClass('in');
});*/

/*Scroll header
$(window).scroll(function(){
$('.header').toggleClass('scrolled', $(this).scrollTop() > 50);
});*/
//Setter height lik bildets height
$('.animation-container').height($('.animation-container > img').height());


$( ".bv-controls .toggle-buildings" ).click(function() {
  if($("#boligvelger .buildings").hasClass("show")){
    $("#boligvelger .buildings").removeClass("show");
    $(this).html("Klikk her");
  }
  else{
    $("#boligvelger .buildings").addClass("show");
    $(this).html("Ta bort");
  }

});

$( ".bv-controls-mini .toggle-buildings" ).click(function() {
  if($("#boligvelger .buildings").hasClass("show")){
    $("#boligvelger .buildings").removeClass("show");
    $(this).removeClass("active");
  }
  else{
    $("#boligvelger .buildings").addClass("show");
    $(this).addClass("active");
  }

});


//loop();




});

$('#burger').click(function(){
  $(this).toggleClass('open');
  $( '.hamburger' ).toggleClass('is-active');
  $('body').toggleClass("disablescroll");
  $('.meny').toggleClass("menytoggler");
})

$('.meny ul').click(function(){
  $( '.hamburger' ).toggleClass('is-active');
  $('body').toggleClass("disablescroll");
  $('.meny').toggleClass("menytoggler");
})

$(document).ready(function(){
  $("a").on('click', function(event) {
    if (this.hash !== "") {
      event.preventDefault();
      var hash = this.hash;

      if ($(window).width() < 767) {
        $('html, body').animate({
          scrollTop: $(hash).offset().top
        }, 800, function(){
          window.location.hash = hash;
        });
      }
      else  {
        $('html, body').animate({
          scrollTop: $(hash).offset().top
        }, 800, function(){
          window.location.hash = hash;
        });
      }
    }
  });
});


/* GAMMEL BV JS */

  $('body').on( 'click', function(e) {
    var target = $(e.target);
    if(!target.is('.grid') && !target.is('.element-item')  && !target.is('.content') && !target.is('.boenhet')) {
      $('.content').removeClass('aktiv');
      $('.popup-container').removeClass('aktiv');
      $('.element-item').removeClass('marked');
      $('.content-backdrop').removeClass('backdrop-in');
    }
    else if(target.is('.element-item')){
      if($('.content').hasClass('aktiv')){
        $('.content').removeClass('aktiv');
        $('.popup-container').removeClass('aktiv');
        $('.element-item').removeClass('marked');
        $('.content-backdrop').removeClass('backdrop-in');
      }
      var curr_id = target.find('.boenhet_id').text();
      $('.content'+curr_id).toggleClass('aktiv');
      $('.popup-container').addClass('aktiv');
      $('.boenhet_id:contains('+curr_id+')').parent().addClass('marked');
      $('.content-backdrop').toggleClass('backdrop-in');
    }
    else if(target.is('.boenhet')){
      var currentID = target.attr("data-boenhetid");
      $('.boenhet').removeClass("highlight");

      if($('.content').hasClass('aktiv')){
        $('.content').removeClass('aktiv');
        $('.popup-container').removeClass('aktiv');
        $('.element-item').removeClass('marked');
        $('.content-backdrop').removeClass('backdrop-in');
      }
      $('.content'+currentID).toggleClass('aktiv');
      $('.popup-container').addClass('aktiv');
      $('.boenhet_id:contains('+currentID+')').parent().addClass('marked');
      $('.content-backdrop').toggleClass('backdrop-in');

     SetPopUpInfo(currentID);

    }
  });

  $('.tomte-list-container .detail-row').click(function(){
    var currentID = $(this).attr("data-attribute-tomteid");
    $('.boenhet').removeClass("highlight");
    $('.boenhet[data-boenhetid="'+currentID+'"]').addClass("highlight");
  })


function SetPopUpInfo(currentID){

  for (var i = 0; i < tomteVariables.length; i++) {
    if(tomteVariables[i].id==currentID){
      var status = "";
      if(tomteVariables[i].status.value!="ledig"){
        status+=" - "+tomteVariables[i].status.label;
      }
      $('.popup .header-row .tomtenr').html(currentID+status);
      $('.popup .detail-container .areal').html(tomteVariables[i].areal+" kvm");
      if(tomteVariables[i].status.value=="ledig" || tomteVariables[i].status.value=="reservert"){
        $('.popup .detail-container .pris_pr_kvm').html(tomteVariables[i].pris_pr_kvm+",- kr");
      }
      if(tomteVariables[i].status.value=="solgt"){
        $('.popup .detail-container .pris_pr_kvm').html("-");
      }
      //$('.popup .detail-container .pris_pr_kvm').html("Ta kontakt");
      $('.popup .detail-container .pris_pr_kvm_planeringskost').html(tomteVariables[i].pris_pr_kvm_planeringskost+",- kr");

        $('.popup .detail-container .annen_info').html(tomteVariables[i].annen_info);

    

      //$('.popup .detail-container .sum_total').html(tomteVariables[i].sum_total+",- kr");
    }
  }

}

/* Tooltip */
if ($(window).width() > 1024) {
var tooltip = document.querySelectorAll('.boenhettooltip');
document.addEventListener('mousemove', fn, false);
function fn(e) {
  var hoyde = $("#boligvelger").offset();
  var bredde = $(".boenhettooltip").outerWidth();
  var xpos= (e.pageX - (bredde/2));
  var ypos= (e.pageY - (hoyde.top - 75));
  tooltip[0].style.left = xpos + 'px';
  tooltip[0].style.top = ypos + 'px';
}

$( ".boenhet" ).hover(
  function() {
    var currentID = $(this).attr("data-boenhetid");

    for (var i = 0; i < tomteVariables.length; i++) {
      if(tomteVariables[i].id==currentID){
        var status = "";
        if(tomteVariables[i].status.value!="ledig"){
          status+=" - "+tomteVariables[i].status.label;
        }

        $('.boenhettooltip .header-row .tomtenr').html(currentID+status);
        $('.boenhettooltip .detail-container .areal').html(tomteVariables[i].areal+" kvm");
        if(tomteVariables[i].status.value=="ledig" || tomteVariables[i].status.value=="reservert") {
          $('.boenhettooltip .detail-container .pris_pr_kvm').html(tomteVariables[i].pris_pr_kvm+",- kr");
        }
        if(tomteVariables[i].status.value=="solgt"){
          $('.boenhettooltip .detail-container .pris_pr_kvm').html("-");
        }

        //$('.boenhettooltip .detail-container .pris_pr_kvm').html("Ta kontakt");
        $('.boenhettooltip .detail-container .pris_pr_kvm_planeringskost').html(tomteVariables[i].pris_pr_kvm_planeringskost+",- kr");
        $('.boenhettooltip .detail-container .sum_total').html(tomteVariables[i].sum_total+",- kr");
        $('.boenhettooltip .detail-container .annen_info').html(tomteVariables[i].annen_info);
      }
    }
    $(".boenhettooltip").addClass( "tooltip-visible" );
  }, function() {
    $(".boenhettooltip").removeClass( "tooltip-visible" );
  }
);
}
